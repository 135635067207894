<script>
// Cool way to render Vue components from HTML Strings
// https://medium.com/haiiro-io/compile-markdown-as-vue-template-on-nuxt-js-1c606c15731c
import VueWithCompiler from "vue/dist/vue.esm";
import draggable from "vuedraggable";
import Vue from "vue";
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

// Use vuetify for this sub component!
Vue.use(Vuetify);

export default {

	components: {
		draggable,
	},
	props: {
		html: {
			type: String,
			default: "",
		},
		responses: {
			type: Array,
		},
		words: {
			type: Array
		},
	},
	methods: {
		revert(arr_vals) {
			if (arr_vals.length === 2) {
				this.words.push(arr_vals[1]);
				arr_vals.splice(1);
			}
		},
	},
	render(h) {
		const compiled = VueWithCompiler.compile("<div>" + this.html + "</div>");

		// Création d'un nouveau composant pour forcer le render
		const DynamicComponent = {
			render: compiled.render,
			staticRenderFns: compiled.staticRenderFns,
			props: ['responses', 'words'],
			methods: {
				revert: this.revert
			}
		};

		return h(DynamicComponent, {
			props: {
				responses: this.responses,
				words: this.words
			}
		});
	},
};
</script>