import { render, staticRenderFns } from "./SelectBlocLevel.vue?vue&type=template&id=964b43a4&scoped=true"
import script from "./SelectBlocLevel.vue?vue&type=script&lang=js"
export * from "./SelectBlocLevel.vue?vue&type=script&lang=js"
import style0 from "./SelectBlocLevel.vue?vue&type=style&index=0&id=964b43a4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "964b43a4",
  null
  
)

export default component.exports