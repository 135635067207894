<template>
  <div v-if="datas" class="mobile-reduced">
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div v-if="datas.user_content" class="exercise-question flex-display">
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exercice ' + bloc.exercise_index + ' - Drag and drop'"
      ></exercise-infos>
      <h2>
        <span v-if="datas.exercise_index"
          >Exercice {{ datas.exercise_index }} -</span
        >
        <span>Drag and drop</span>
      </h2>
      <p class="exercise-help">
        Glissez les étiquettes dans les cases du tableau prévues pour la
        réponse.
      </p>
      <p
        v-if="
          bloc.settings.infos &&
          bloc.settings.infos[level] &&
          bloc.settings.infos[level].consigne
        "
        class="consigne"
        v-html="
          bloc.settings.infos[level].consigne.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          )
        "
      ></p>
      <div class="texte">
        <draggable
          group="words"
          v-model="initial_words"
          class="grid-etiquettes"
          :style="
            'grid-template-columns: repeat(' +
            grid_settings.cols +
            ', 1fr); grid-template-rows: min-content 1fr;'
          "
          :move="checkMove"
        >
          <span
            v-for="(word, word_index) in initial_words"
            v-bind:key="'origine_' + word_index"
            class="etiquette"
            >{{ word }}</span
          >
        </draggable>
      </div>
      <div>
        <div
          class="grid-etiquettes-dest"
          :style="
            'grid-template-columns: repeat(' +
            grid_settings.cols +
            ', 1fr); grid-template-rows: repeat(' +
            (grid_settings.rows + 1) +
            ', 1fr);'
          "
        >
          <div
            v-for="(item, index) in datas.user_content.content"
            class="grid-etiquettes-header"
            v-bind:key="index"
          >
            {{ item.question }}
          </div>

          <div
            v-for="item in destination_words.length"
            v-bind:key="'destination_words' + (item - 1)"
            class="etiquettes-dest-container"
          >
            <draggable
              @move="checkMove"
              @add="checkAdd()"
              group="words"
              v-model="destination_words[item - 1]"
            >
              <span
                v-for="(word2, word_index2) in destination_words[item - 1]"
                v-bind:key="'origine_' + word_index2"
                class="etiquette"
                >{{ word2 }}</span
              >
            </draggable>
          </div>
        </div>
        <div class="texte">
          <div
            class="btn-container"
            v-if="!results && initial_words.length == 0"
          >
            <v-btn
              v-if="button_label"
              color="#ec446d"
              v-on:click="valid()"
              class="text-white"
              elevation="0"
              >{{ button_label }}</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="results">
        <div class="exercise-results">
          <div class="score">
            <div>
              <span>{{ results.score }}</span>
            </div>
            <div class="opinion">
              <img :src="results.emoticon" />
              <span>{{ results.opinion }}</span>
            </div>
          </div>
          <div class="resultats" v-if="fulltext">
            <div class="flex-results-column">
              <div
                v-for="(column, column_index) in datas.user_content.content"
                v-bind:key="column_index"
              >
                <div>
                  <strong>{{ column.question }}</strong>
                </div>
                <div
                  v-for="(row, row_index) in column.answers"
                  v-bind:key="row_index"
                >
                  <span>{{ row.answer }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="exercise-inputs">
          <div></div>
          <div>
            <div>
              <span
                v-if="exercise_previous_datas || user_role == 'ROLE_TEACHER'"
                @click="swapContent()"
                :class="getClass()"
                href
                >{{ getLink() }}</span
              >
            </div>
            <div>
              <v-btn
                v-if="
                  getExerciseStudentAttempts() <= 1 || exercise_previous_datas
                "
                v-model="showButton"
                color="#ec446d"
                class="text-white"
                v-on:click="doItAgain()"
                elevation="0"
                >Recommencer</v-btn
              >
              <studenttoolbar
                :user_role="user_role"
                :level="level"
                :bloc="datas"
                :show_send_button="results"
                :results="results"
                :exercise_previous_datas="exercise_previous_datas"
                @updateExercisePreviousDatas="updateExercisePreviousDatas"
              />
            </div>
          </div>
        </div>
      </div>
      <send-exercise
        v-if="user_role != 'ROLE_STUDENT'"
        :bloc="bloc"
        :has_exercices_to_send="has_exercices_to_send"
        @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
      ></send-exercise>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import Exercise from "../Shared/Functions/Exercise";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    draggable,
    studenttoolbar: StudentToolbar,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      user_uuid: null,
      showButton: true,
      fulltext: false,
      datas: null,
      results: null,
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      button_label: null,
      initial_words: null,
      destination_words: [],
      grid_settings: {},
      other_words: [],
      words_list: [],
      btn_is_disabled: true,
      notation: Exercise.getNotation(),
      exercise_previous_datas: null,
    };
  },
  mounted() {
    this.user_uuid = this.$store.getters.user_uuid;
    this.datas = this.bloc;
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
    if (this.datas != null && this.datas !== undefined) {
      if (
        this.datas.user_content != null &&
        this.datas.user_content !== undefined
      ) {
        this.initGame();
      }
    }
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
	watch:{
		level(lvl_index) {
			this.bloc.user_content = this.bloc.other_levels[lvl_index];
			this.initGame();
		}
	},
  methods: {
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    checkAdd() {
      for (let i = 0; i < this.destination_words.length; i++) {
        if (this.destination_words[i].length > 1) {
          for (let j = 1; j < this.destination_words[i].length; j++) {
            this.initial_words.push(this.destination_words[i][j]);
          }
          this.destination_words[i] = [this.destination_words[i][0]];
        }
      }
    },
    checkMove() {},
    log() {},
    change() {},
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    initGame: function () {
      this.initial_words = this.bloc.user_content.sentences;
			this.destination_words = [];
      this.button_label = "Valider";

      let max_items = 0;
      for (let i = 0; i < this.bloc.user_content.content.length; i++) {
        max_items = Math.max(
          max_items,
          this.bloc.user_content.content[i].answers.length
        );
      }
      for (
        let i = 0;
        i < max_items * this.bloc.user_content.content.length;
        i++
      ) {
        this.destination_words[i] = [];
      }
      this.grid_settings.cols = this.bloc.user_content.content.length;
      this.grid_settings.rows =
        this.bloc.user_content.content[0].answers.length;
    },
    valid: function () {
      if (this.button_label == "Valider") {
        this.results = this.getResults();
      } else {
        this.initGame();
      }
    },
    getResults() {
      let score = 0;
      for (let i = 0; i < this.destination_words.length; i++) {
        let soustracteur = parseInt(i / this.grid_settings.cols);
        let column = i - soustracteur * this.grid_settings.cols;

        for (
          let j = 0;
          j < this.bloc.user_content.content[column].answers.length;
          j++
        ) {
          if (
            this.destination_words[i] ==
            this.bloc.user_content.content[column].answers[j].answer
          ) {
            score++;
          }
        }
      }

      let percentage = parseInt(
        100 * (score / this.bloc.user_content.sentences.length)
      );
      let result_index = null;
      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else if (percentage > 70 && percentage < 100) {
        result_index = "excellent";
      } else {
        result_index = "perfect";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
        case "perfect":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      if (result_index != null) {
        let notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
        this.setResults(notation);
        this.datas.user_content.destination_words = this.destination_words;
        return notation;
      }
    },
    setResults(notation) {
      let results = {
        level: this.level,
        date: Date.now(),
        uuid: this.datas.uuid,
        datas: this.datas,
        notation: notation,
      };
      this.results = results;
    },
    doItAgain() {
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.results = null;
      this.initGame();
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
  },
};
</script>
<style scoped></style>
