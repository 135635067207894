<template>
  <div v-if="datas" class="mobile-reduced">
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div v-if="datas.user_content" class="exercise-question flex-display">
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exerecice ' + bloc.exercise_index + ' -  Compléter un tableau'"
      ></exercise-infos>
      <h2>
        <span v-if="datas.exercise_index"
          >Exercice {{ bloc.exercise_index }} -</span
        >
        <span>Compléter un tableau</span>
      </h2>
      <p
        v-if="exercice_infos.exercise_variant !== 'textfield'"
        class="exercise-help"
      >
        Glissez les étiquettes dans les cases du tableau prévues pour la
        réponse.
      </p>
      <p
        v-if="exercice_infos.exercise_variant == 'textfield'"
        class="exercise-help"
      >
        Saisissez le texte dans les cases du tableau prévues pour la réponse.
      </p>
      <p
        v-if="
          bloc.settings.infos &&
          bloc.settings.infos[level] &&
          bloc.settings.infos[level].consigne
        "
        class="consigne"
        v-html="
          bloc.settings.infos[level].consigne.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          )
        "
      ></p>
      <draggable
        v-if="exercice_infos.exercise_variant !== 'textfield'"
        group="words"
        v-model="initial_words"
        class="etiquettes"
      >
        <span
          v-for="(word, word_index) in initial_words"
          v-bind:key="word_index"
          class="etiquette"
          v-html="word.name"
        ></span>
      </draggable>
      <div
        class="texte"
        :class="getTableClass(bloc.settings.infos[level].headers)"
      >
        <blocTableRenderVue
          class="regular-table table-drop"
          :class="datas.settings.infos[level].exercise_variant"
          :responses="responses_list"
          :words="initial_words"
          :html="datas.user_content.original_bis"
        />
        <div class="btn-container mt-2" v-if="!results">
          <v-btn
            class="mr-4"
            text
            dark
            color="#FF8000"
            @click="clear()"
            elevation="0"
            >Tout effacer</v-btn
          >

          <v-btn
            :disabled="valid_form"
            v-if="button_label"
            color="#ec446d"
            @click="valid()"
            class="text-white"
            elevation="0"
            >{{ button_label }}</v-btn
          >
        </div>
        <div class="btn-container mt-2" v-else>
          <studenttoolbar
            :user_role="user_role"
            :level="level"
            :bloc="datas"
            :show_send_button="results"
            :results="results"
            :exercise_previous_datas="exercise_previous_datas"
            @updateExercisePreviousDatas="updateExercisePreviousDatas"
          />
        </div>
      </div>
      <div v-if="results">
        <div class="exercise-results">
          <div
            class="score"
            v-if="exercice_infos.exercise_variant !== 'textfield'"
          >
            <div>
              <span>{{ results.score }}</span>
            </div>
            <div class="opinion">
              <img :src="results.emoticon" />
              <span>{{ results.opinion }}</span>
            </div>
          </div>
          <div class="resultats" v-if="fulltext">
            <div>
              <div
                v-if="
                  exercice_infos.exercise_variant !== 'textfield' &&
                  datas.user_content.original !== undefined &&
                  datas.user_content.original != null
                "
                class="original-text"
                v-html="datas.user_content.original"
              ></div>
              <div v-else>
                <div
                  v-if="datas.settings.infos[level].corrige_professeur"
                  v-html="datas.settings.infos[level].corrige_professeur"
                ></div>
                <div v-else>
                  <strong>Ce corrigé sera disponible prochainement.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="exercise-inputs">
          <div></div>
          <div>
            <div>
              <span
                v-if="datas.settings.infos[level].corrige_professeur"
                @click="swapContent()"
                :class="getClass()"
                href
                >{{ getLink() }}</span
              >
            </div>
            <div>
              <v-btn
                v-if="
                  getExerciseStudentAttempts() <= 1 || exercise_previous_datas
                "
                v-model="showButton"
                color="#ec446d"
                class="text-white"
                @click="doItAgain()"
                elevation="0"
                >Recommencer</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <send-exercise
      v-if="user_role != 'ROLE_STUDENT'"
      :bloc="bloc"
      :has_exercices_to_send="has_exercices_to_send"
      @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
    ></send-exercise>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import blocTableRenderVue from "./BlocTableRenderVue.vue";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import Exercise from "../Shared/Functions/Exercise";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    draggable,
    blocTableRenderVue,
    studenttoolbar: StudentToolbar,
    "exercise-infos": ExerciseInfos,
    "send-exercise": SendExercise,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      exercise_previous_datas: null,
      showButton: true,
      fulltext: false,
      datas: null,
      exercice_infos: null,
      results: null,
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      button_label: null,
      initial_words: null,
      other_words: [],
      words_list: [],
      responses_list: [],
      btn_is_disabled: true,
      notation: Exercise.getNotation(),
    };
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  mounted() {
    this.datas = this.bloc;
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
    this.exercice_infos = this.datas.settings.infos[this.level];
    if (this.datas != null && this.datas !== undefined) {
      if (
        this.datas.user_content != null &&
        this.datas.user_content !== undefined
      ) {
        this.initGame();
      }
    }
  },
  computed: {
    valid_form() {
      if (this.exercice_infos.exercise_variant !== "textfield")
        return this.initial_words.length > 0;
      return (
        this.responses_list.filter(
          (response) => !response.value || response.value.length <= 0
        ).length > 0
      );
    },
  },
  watch: {
		level:{
			handler(lvl_index) {
				if (
					this.bloc.other_levels !== undefined &&
					this.bloc.other_levels != null &&
					this.bloc.other_levels[lvl_index] !== undefined &&
					this.bloc.other_levels[lvl_index] != null
				) {
					this.bloc.user_content = this.bloc.other_levels[lvl_index];
					this.reinitGame(this.bloc);
				}
			},
			immediate: true,
		},
   /*datas: {
      handler(datas) {
        if (datas) {
          this.reinitGame(datas);
        }
      },
      immediate: true,
      deep: true,
    },*/
  },
  methods: {
    reinitGame(datas) {
      this.datas = datas;
      this.exercice_infos = this.datas.settings.infos[this.level];
      this.initGame();
    },
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    getTableClass(headers) {
      let class_name = "";
      switch (headers) {
        case "1ère ligne":
          class_name = "table_first_row";
          break;
        case "1ère colonne":
          class_name = "table_first_col";
          break;
        case "1ère ligne et 1ère colonne":
          class_name = "table_first_row table_first_col";
          break;
        default:
          class_name = "table_no_header";
      }
      return class_name;
    },
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    injectDraggables() {
      let i = 0;

      this.datas.user_content.original_bis = this.datas.user_content.original;

      // Replace all <td> with a mother div with display set at flex and flex-wrap at wrap for habe childrens content inline!
      this.datas.user_content.original_bis =
        this.datas.user_content.original_bis.replace(
          /<td[^>]{0,}>/g,
          function (match) {
            return match + "<div class='d-flexwrap'>";
          }
        );
      this.datas.user_content.original_bis =
        this.datas.user_content.original_bis.replaceAll("</td>", "</div></td>");

      for (i in this.responses_list) {
        if (this.exercice_infos.exercise_variant !== "textfield")
          this.datas.user_content.original_bis =
            this.datas.user_content.original_bis.replace(
              /<strong>([\s\S]*?)<\/strong>/,
              '<draggable class="p-initial" :class="responses[' +
                i +
                "].value.length <= 0 ? 'inner-draggable' : ''\" v-model=\"responses[" +
                i +
                '].value" @change="revert(responses[' +
                i +
                '].value)" group="words"/> <span v-for="(word, word_index) in responses[' +
                i +
                '].value" :key="word_index" class="etiquette">{{word.name}}</span>'
            );
        else
          this.datas.user_content.original_bis =
            this.datas.user_content.original_bis.replace(
							/<strong>([\s\S]*?)<\/strong>/,
              '<v-textarea style="width: 215px;" no-resize auto-grow placeholder="' +
                this.responses_list[i].placeholder +
                '" v-model="responses[' +
                i +
                '].value"></v-textarea>'
            );
      }
    },
    initGame() {
      let authorize_words = [];
      this.initial_words = [];
      this.responses_list = [];
      this.datas.user_content.words.forEach((element, key) => {
        this.initial_words.push({
          name: element,
          index: key,
        });
        this.other_words[key] = [];
        this.button_label = "Valider";
        authorize_words.push(element.trim());
      });
      let tmp_original = this.datas.user_content.original,
        tmp_ret = null;

      tmp_original.split("<strong>").forEach((element) => {
        tmp_ret = element.split("</strong>");
				let elmt = tmp_ret[0].replace('<br />', '');

        if (
          tmp_ret.length === 2 &&
          authorize_words.includes(elmt.trim())
        ) {
          // For not textfield exercices!
          if (this.exercice_infos.exercise_variant !== "textfield")
            this.responses_list.push({
              response: elmt,
              value: [],
            });
          else
            this.responses_list.push({
              placeholder: elmt,
              value: "",
            });
        }
      });
      this.injectDraggables();
    },
    clear() {
      this.responses_list.forEach((response) => {
        this.initial_words = this.initial_words.concat(response.value);
        response.value = [];
      });
    },
    valid() {
      if (this.button_label === "Valider") {
        this.results = this.getResults();
      } else {
        this.initGame();
      }
    },
    getResults() {
      let good_answers = [],
        answers = [];

      this.responses_list.forEach((response) => {
        if (
          response.value.length > 0 &&
          response.response === response.value[0].name
        )
          good_answers.push(response.response);
        answers.push(response.value);
      });

      // Compute percentage of valid answers!
      let percentage =
          good_answers.length > 0
            ? parseInt(
                (100 * good_answers.length) /
                  (this.initial_words.length + answers.length)
              )
            : 0,
        result_index = null;

      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else if (percentage > 70 && percentage < 100) {
        result_index = "excellent";
      } else {
        result_index = "perfect";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
        case "perfect":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      let notation = null;

      if (this.exercice_infos.exercise_variant === "textfield")
        notation = {
          score: null,
          opinion: null,
          emoticon: null,
          percentage: null,
          answers: answers,
        };

      if (result_index) {
        notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
      }
      this.datas.user_content.responses_list = this.responses_list;
      return notation;
    },
    doItAgain() {
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.results = null;
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
      this.initGame();
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
  },
};
</script>
