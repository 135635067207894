<template>
  <div
    :level="level"
    class="settings-classes"
    :ref="'itm' + bloc.id"
    :id="'itm' + bloc.id"
  >
    <div>
      <fullscreen fullscreen-class="fullscreen-active" v-model="realfullscreen">
        <div class="full-screen-button" v-if="blocAllowedForScaling(bloc.kind)">
          <v-tooltip bottom v-if="!realfullscreen">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="realfullscreen = !realfullscreen"
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon large color="blue darken-2">mdi-fullscreen</v-icon>
              </v-btn>
            </template>
            <span>Mode plein écran</span>
          </v-tooltip>
        </div>
        <div class="exit-fullscreen" @click="exitFullscreen">
          Quitter le mode plein écran
        </div>
        <div v-if="realfullscreen && zoom_is_ok" class="zoom-toolbar">
          <div>
            <v-slider
              dark
              append-icon="mdi-magnify-plus"
              v-model="scale"
              min="1"
              max="3"
              step=".025"
              vertical
              ticks="always"
              tick-size="4"
              dense
            ></v-slider>
          </div>
        </div>
        <div
          v-if="
            bloc.settings.classes !== null &&
            bloc.settings.classes.indexOf('bloc-plus-loin') != -1 &&
            bloc.settings.classes.indexOf('first-of-group') != -1
          "
          class="bubble-plus-loin"
        >
          <v-icon small color="white">mdi-alert-octagram</v-icon>&nbsp;
          <span>Pour aller plus loin</span>
        </div>
        <select-bloc-level
          v-if="user_role != 'ROLE_STUDENT'"
          :datas="bloc"
          :level="level"
          @changeLevel="changeLevel"
          @reinitGame="reinitGame"
          :user_role="user_role"
        ></select-bloc-level>

        <div class="group-name" v-if="bloc.group_name">
          <div class="group-name-header">
            <div>{{ bloc.group_name }}</div>
          </div>
        </div>
        <div
          v-if="bloc && bloc.user_content"
          class="bloc-wrapper"
          :data-kind="bloc.kind"
          v-on:dblclick="fullscreen(bloc)"
          :style="{ zoom: scale }"
        >
          <bloc-image
            v-if="bloc.kind == 'IMAGE'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-image>
          <bloc-caption-image
            v-else-if="bloc.kind == 'CAPTION'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-caption-image>
          <bloc-caption-drop
            v-else-if="bloc.kind == 'CAPTIONDROP'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-caption-drop>
          <bloc-timeline-drop
            v-else-if="bloc.kind == 'TIMELINEDROP'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-timeline-drop>
          <bloc-html
            v-else-if="bloc.kind == 'HTML'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-html>
          <bloc-title
            v-else-if="bloc.kind == 'TITLE'"
            :bloc="bloc"
          ></bloc-title>
          <bloc-genially
            v-else-if="bloc.kind == 'GENIALLY'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
          ></bloc-genially>
          <bloc-rawhtml
            v-else-if="bloc.kind == 'RAWHTML'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-rawhtml>
          <bloc-svgmap
            v-else-if="bloc.kind == 'SVGMAP'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-svgmap>
          <bloc-timeline
            v-else-if="bloc.kind == 'TIMELINE'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
          ></bloc-timeline>
          <bloc-diagram
            v-else-if="bloc.kind == 'DIAGRAM'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
          ></bloc-diagram>
          <bloc-video
            v-else-if="bloc.kind == 'VIDEO'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
          ></bloc-video>
          <bloc-qcm
            v-else-if="bloc.kind == 'QCM'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-qcm>
          <bloc-truefalse
            v-else-if="bloc.kind == 'TRUEFALSE'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-truefalse>
          <bloc-drag-and-drop
            v-else-if="bloc.kind == 'DRAGANDDROP'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-drag-and-drop>
          <bloc-associations-links
            v-else-if="bloc.kind == 'ASSOCIATIONSLINKS'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-associations-links>
          <bloc-free-question
            v-else-if="bloc.kind == 'FREEQUESTION'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-free-question>
          <bloc-written-trace
            v-else-if="bloc.kind == 'WRITTENTRACE'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-written-trace>
          <bloc-textwithgaps
            v-else-if="bloc.kind == 'TEXTWITHGAPS'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-textwithgaps>
          <bloc-tabledrop
            v-else-if="bloc.kind == 'TABLEDROP'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-tabledrop>
          <bloc-texttoorder
            v-else-if="bloc.kind == 'TEXTTOORDER'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-texttoorder>
          <bloc-association
            v-else-if="bloc.kind == 'ASSOCIATIONS'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-association>
          <bloc-no-answer-needed
            v-else-if="bloc.kind == 'NOANSWERNEEDED'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-no-answer-needed>
          <bloc-audio
            v-else-if="bloc.kind == 'AUDIO'"
            :bloc="bloc"
            :level="level"
            :user_role="user_role"
          ></bloc-audio>
          <div v-else>
            <h3>EN COURS DE DEVELOPPEMENT : [{{ bloc.kind }}]</h3>
          </div>
        </div>
        <alert-other-level
          :bloc="bloc"
          :level="level"
          :user_role="user_role"
        ></alert-other-level>
        <div
          v-if="other_attached"
          class="bloc"
          :data-kind="other_attached.kind"
          v-on:dblclick="fullscreen(other_attached)"
        >
          <bloc-image
            v-if="other_attached.kind == 'IMAGE'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-image>
          <bloc-caption-image
            v-else-if="other_attached.kind == 'CAPTION'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-caption-image>
          <bloc-caption-drop
            v-else-if="other_attached.kind == 'CAPTIONDROP'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-caption-drop>
          <bloc-timeline-drop
            v-else-if="other_attached.kind == 'TIMELINEDROP'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-timeline-drop>
          <bloc-html
            v-else-if="other_attached.kind == 'HTML'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-html>
          <bloc-title
            v-else-if="bloc.kind == 'TITLE'"
            :bloc="other_attached"
          ></bloc-title>
          <bloc-genially
            v-else-if="other_attached.kind == 'GENIALLY'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
          ></bloc-genially>
          <bloc-rawhtml
            v-else-if="other_attached.kind == 'RAWHTML'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
          ></bloc-rawhtml>
          <bloc-svgmap
            v-else-if="other_attached.kind == 'SVGMAP'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-svgmap>
          <bloc-timeline
            v-else-if="other_attached.kind == 'TIMELINE'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
          ></bloc-timeline>
          <bloc-diagram
            v-else-if="other_attached.kind == 'DIAGRAM'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
          ></bloc-diagram>
          <bloc-video
            v-else-if="other_attached.kind == 'VIDEO'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
          ></bloc-video>
          <bloc-qcm
            v-else-if="other_attached.kind == 'QCM'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-qcm>
          <bloc-truefalse
            v-else-if="other_attached.kind == 'TRUEFALSE'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-truefalse>
          <bloc-drag-and-drop
            v-else-if="other_attached.kind == 'DRAGANDDROP'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-drag-and-drop>
          <bloc-association
            v-else-if="other_attached.kind == 'ASSOCIATIONS'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-association>
          <bloc-no-answer-needed
            v-else-if="other_attached.kind == 'NOANSWERNEEDED'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-no-answer-needed>
          <bloc-free-question
            v-else-if="other_attached.kind == 'FREEQUESTION'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
            :textbook_version="textbook_version"
          ></bloc-free-question>
          <bloc-written-trace
            v-else-if="other_attached.kind == 'WRITTENTRACE'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-written-trace>
          <bloc-textwithgaps
            v-else-if="other_attached.kind == 'TEXTWITHGAPS'"
            :bloc="other_attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-textwithgaps>
        </div>
        <div
          v-if="attached"
          class="bloc"
          :data-kind="attached.kind"
          v-on:dblclick="fullscreen(attached)"
        >
          <bloc-image
            v-if="attached.kind == 'IMAGE'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-image>
          <bloc-caption-image
            v-else-if="attached.kind == 'CAPTION'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
          ></bloc-caption-image>
          <bloc-caption-drop
            v-else-if="attached.kind == 'CAPTIONDROP'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-caption-drop>
          <bloc-timeline-drop
            v-else-if="attached.kind == 'TIMELINEDROP'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-timeline-drop>
          <bloc-html
            v-else-if="attached.kind == 'HTML'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-html>
          <bloc-title
            v-else-if="bloc.kind == 'TITLE'"
            :bloc="attached"
          ></bloc-title>
          <bloc-genially
            v-else-if="attached.kind == 'GENIALLY'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
          ></bloc-genially>
          <bloc-rawhtml
            v-else-if="attached.kind == 'RAWHTML'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
          ></bloc-rawhtml>
          <bloc-svgmap
            v-else-if="attached.kind == 'SVGMAP'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
          ></bloc-svgmap>
          <bloc-timeline
            v-else-if="attached.kind == 'TIMELINE'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
          ></bloc-timeline>
          <bloc-diagram
            v-else-if="attached.kind == 'DIAGRAM'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
          ></bloc-diagram>
          <bloc-video
            v-else-if="attached.kind == 'VIDEO'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
          ></bloc-video>
          <bloc-qcm
            v-else-if="attached.kind == 'QCM'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-qcm>
          <bloc-truefalse
            v-else-if="attached.kind == 'TRUEFALSE'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-truefalse>
          <bloc-drag-and-drop
            v-else-if="attached.kind == 'DRAGANDDROP'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-drag-and-drop>
          <bloc-associations-links
            v-else-if="attached.kind == 'ASSOCIATIONSLINKS'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-associations-links>
          <bloc-free-question
            v-else-if="attached.kind == 'FREEQUESTION'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
            :textbook_version="textbook_version"
          ></bloc-free-question>
          <bloc-written-trace
            v-else-if="attached.kind == 'WRITTENTRACE'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-written-trace>
          <bloc-textwithgaps
            v-else-if="attached.kind == 'TEXTWITHGAPS'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-textwithgaps>
          <bloc-association
            v-else-if="attached.kind == 'ASSOCIATIONS'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :has_exercices_to_send="has_exercices_to_send"
            :textbook_version="textbook_version"
            @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
          ></bloc-association>
          <bloc-no-answer-needed
            v-else-if="attached.kind == 'NOANSWERNEEDED'"
            :bloc="attached"
            :level="level"
            :user_role="user_role"
            :textbook_version="textbook_version"
          ></bloc-no-answer-needed>
        </div>
      </fullscreen>
    </div>
  </div>
</template>

<script>
import BlocImage from "../Shared/BlocImage.vue";
import BlocCaptionImage from "../Shared/BlocCaptionImage.vue";
import BlocCaptionDrop from "../Shared/BlocCaptionDrop.vue";
import BlocTimelineDrop from "../Shared/BlocTimelineDrop.vue";

import BlocHtml from "../Shared/BlocHtml.vue";
import BlocTitle from "../Shared/BlocTitle.vue";

import BlocGenially from "../Shared/BlocGenially.vue";
import BlocRawHtml from "../Shared/BlocRawHtml.vue";
import BlocSvgMap from "../Shared/BlocSvgMap.vue";

import BlocTimeline from "../Shared/BlocTimeline.vue";
import BlocDiagram from "../Shared/BlocDiagram.vue";

import BlocVideo from "../Shared/BlocVideo.vue";
import BlocQcm from "../Shared/BlocQcm.vue";
import BlocTrueFalse from "../Shared/BlocTrueFalse.vue";
import BlocDragAndDrop from "../Shared/BlocDragAndDrop.vue";
import BlocFreeQuestion from "../Shared/BlocFreeQuestion.vue";
import BlocWrittenTrace from "../Shared/BlocWrittenTrace.vue";
import BlocAssociationsLinks from "../Shared/BlocAssociationsLinks.vue";
import BlocTextWithGaps from "../Shared/BlocTextWithGaps.vue";
import BlocTableDrop from "../Shared/BlocTableDrop.vue";
import BlocTextToOrder from "../Shared/BlocTextToOrder.vue";
import AlertOtherLevel from "../Shared/AlertOtherLevel.vue";
import BlocNoAnswerNeeded from "../Shared/BlocNoAnswerNeeded.vue";
import BlocAudio from "../Shared/BlocAudio.vue";

import SelectBlocLevel from "./Tools/SelectBlocLevel.vue";
import VueFullscreen from "vue-fullscreen";

import Vue from "vue";
Vue.use(VueFullscreen);

export default {
  components: {
    "select-bloc-level": SelectBlocLevel,
    "bloc-image": BlocImage,
    "bloc-caption-image": BlocCaptionImage,
    "bloc-caption-drop": BlocCaptionDrop,
    "bloc-timeline-drop": BlocTimelineDrop,
    "bloc-html": BlocHtml,
    "bloc-title": BlocTitle,
    "bloc-genially": BlocGenially,
    "bloc-rawhtml": BlocRawHtml,
    "bloc-svgmap": BlocSvgMap,
    "bloc-timeline": BlocTimeline,
    "bloc-diagram": BlocDiagram,
    "bloc-video": BlocVideo,
    "bloc-qcm": BlocQcm,
    "bloc-truefalse": BlocTrueFalse,
    "bloc-drag-and-drop": BlocDragAndDrop,
    "bloc-free-question": BlocFreeQuestion,
    "bloc-written-trace": BlocWrittenTrace,
    "bloc-associations-links": BlocAssociationsLinks,
    "bloc-textwithgaps": BlocTextWithGaps,
    "bloc-tabledrop": BlocTableDrop,
    "bloc-texttoorder": BlocTextToOrder,
    "bloc-no-answer-needed": BlocNoAnswerNeeded,
    "bloc-audio": BlocAudio,
    "alert-other-level": AlertOtherLevel,
  },
  data() {
    return {
      realfullscreen: false,
      scale: 1,
			level: this.init_level
    };
  },
  props: [
    "bloc",
    "attached",
    "other_attached",
    "init_level",
    "user_role",
    "textbook_version",
    "has_exercices_to_send",
    "zoom_is_ok",
  ],
  mounted() {},
  methods: {
    reinitGame(user_content) {
      this.bloc.user_content = user_content;
    },
    blocAllowedForScaling(kind) {
      const notAllowed = ["TITLE", "ASSOCIATIONSLINKS"];
      if (notAllowed.includes(kind)) {
        return false;
      } else {
        return true;
      }
    },
    changeLevel(level) {
			this.level = level;
    },
    fullscreen(bloc) {
      this.$emit("fullscreen", bloc);
    },
    exitFullscreen() {
      this.scale = 1;
      document.exitFullscreen();
      this.realfullscreen = false;
    },
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
  },
};
</script>
